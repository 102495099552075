import { Link } from 'gatsby';
import { darken } from 'polished';
import React from 'react';

import { css } from "@emotion/react"
import styled from "@emotion/styled";

import { colors } from '../../styles/colors';
import { SocialLink, SocialLinkFb } from '../../styles/shared';
import config from '../../website-config';
import { Facebook } from '../icons/facebook';
import { Twitter } from '../icons/twitter';
import { SubscribeModal } from '../subscribe/SubscribeModal';
import { SiteNavLogo } from './SiteNavLogo';

import ExtendedNav from './dropdownMenu';
import Search from '../Search'
import SearchBackdrop from '../SearchBackdrop'

interface SiteNavProps {
  isHome?: boolean;
  isPost?: boolean;
  post?: any;
}

interface SiteNavState {
  showTitle: boolean;
  showSearch: boolean;
  expand: boolean;
}

class SiteNav extends React.Component<SiteNavProps, SiteNavState> {
  subscribe = React.createRef<SubscribeModal>();
  titleRef = React.createRef<HTMLSpanElement>();
  lastScrollY = 0;
  ticking = false;
  state = { showTitle: false, showSearch: false, expand: false };
  keysPressed = {};

  toggleSearch= () => {
    this.setState({ showSearch: true });
    setTimeout(function()
    {
      let searchInput = document.getElementById("searchInput"); 
      searchInput!=null && searchInput.focus()
    }, 30);
  };

  closeSearch = () => {
    this.setState({ showSearch: false });
  }

  openModal = () => {
    if (this.subscribe.current) {
      this.subscribe.current.open();
    }
  };

  toggleSiteNav = (bool: boolean) => {
    this.setState({ expand: bool })
  };

  componentDidMount(): void {
    this.lastScrollY = window.scrollY;
    if (this.props.isPost) {
      window.addEventListener('scroll', this.onScroll, { passive: true });
    }
    document.addEventListener('keydown', (event) => {
      this.keysPressed[event.key] = true;
   
      if (this.keysPressed['Control'] && this.keysPressed['Shift'] && event.key == 'F') {
          this.toggleSearch();
      }
   });
   
   document.addEventListener('keyup', (event) => {
      delete this.keysPressed[event.key];
   });
  }

  componentWillUnmount(): void {
    window.removeEventListener('scroll', this.onScroll);
  }

  onScroll = () => {
    if (!this.titleRef || !this.titleRef.current) {
      return;
    }

    if (!this.ticking) {
      requestAnimationFrame(this.update);
    }

    this.ticking = true;
  };

  update = () => {
    if (!this.titleRef || !this.titleRef.current) {
      return;
    }

    this.lastScrollY = window.scrollY;

    const trigger = this.titleRef.current.getBoundingClientRect().top;
    const triggerOffset = this.titleRef.current.offsetHeight + 35;

    // show/hide post title
    // TEST-KEEP NAVBAR IN POSTS - EXPERIMENTAL
    if (this.lastScrollY >= trigger + triggerOffset) {
      this.setState({ showTitle: false }); //true
    } else {
      this.setState({ showTitle: false });
    }

    this.ticking = false;
  };

  render(): JSX.Element {
    const { isHome = false, isPost = false, post = {} } = this.props;
    return (
      <>
        {config.showSubscribe && <SubscribeModal ref={this.subscribe} />}
        <nav id="siteNav-nav" css={[this.state.expand ? SiteNavStyles : ShrinkNavHeight ]}>
          <SiteNavLeft className="site-nav-left">
            <SiteNavContent css={[this.state.showTitle ? HideNav : '']}>

              <div id="navitems">
              <ul id="site-nav-menu" css={NavStyles} role="menu">
                {/* TODO: mark current nav item - add class nav-current */}
                
                <li role="menuitem">
                  <Link to="/">Home</Link>
                </li>
                
                 <li role="menuitem">
                  <Link to="/about">About</Link>
                </li>

                {/*<ExtendedNav onMouseEnterCallback={() => {this.toggleSiteNav(true)}} onMouseLeaveCallback={() => {this.toggleSiteNav(false)}}/>*/}
                <SiteNavLogo/>

                <li role="menuitem">
                  <a css={{cursor:"pointer"}} onClick={this.toggleSearch}>Search</a>
                </li>

                <li role="menuitem">
                  <Link to="/contact">Contact</Link>
                </li>
                
              </ul>
              </div>

              {isPost && (
              <div css={{width:"100%"}}>
              
                <NavPostTitle ref={this.titleRef} style={{display:"table",margin:"auto"}} className="nav-post-title">
                  {post.title}
                </NavPostTitle>
              </div>
              )}

              {isHome && (
              <div>
              
                <NavPostTitle ref={this.titleRef} className="nav-post-title">
                  {post.title}
                </NavPostTitle>
              </div>
              )}

            </SiteNavContent>
          </SiteNavLeft>
          <SiteNavRight>
            <SocialLinks>
              {config.facebook && (
                <a
                  className="social-link-fb"
                  css={[SocialLink, SocialLinkFb]}
                  href={config.facebook}
                  target="_blank"
                  title="Facebook"
                  rel="noopener noreferrer"
                >
                  <Facebook />
                </a>
              )}
              {/*config.twitter && (
                <a
                  css={SocialLink}
                  href={config.twitter}
                  title="Twitter"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Twitter />
                </a>
              )*/}
            </SocialLinks>
            {config.showSubscribe && (
              <SubscribeButton onClick={this.openModal}>Subscribe</SubscribeButton>
            )}
          </SiteNavRight>
        </nav>

        <Search closeSearch={this.closeSearch} show={this.state.showSearch} />
        <SearchBackdrop show={this.state.showSearch} click={this.closeSearch}/>

      </>
    );
  }
}

export const SiteNavMain = css`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1000;
  /* background: color(var(--darkgrey) l(-5%)) */
  background: ${darken('0.05', colors.darkgrey)};
  /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
  Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;*/

  @media (max-width: 768px) {
    padding-right: 0;
    padding-left: 0;
  }
`;

const SiteNavStyles = css`
  position: absolute;
  width:100%;
  background:black;
  z-index: 100;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  overflow-y: hidden;
  height: 64px;
  font-size: 1.3rem;
  transition: all 0.25s cubic-bezier(0.4, 0.01, 0.165, 0.99);

  /* finally even better */
  display: flex;
  width: 100vw;
  justify-content: center;
  padding-left:0;

  /* YESSS */
  /*margin-left:-16em;*/
  /* left: 16em; */
  left:0;

  height: 10em;
  overflow: hidden;
  background: transparent;
  width:100%;

  @media (max-width: 768px) {
    height:0px;
  }
`;

const ShrinkNavHeight = css`
  position: absolute;
  width:100%;
  background:black;
  z-index: 100;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  overflow-y: hidden;
  height: 64px;
  font-size: 1.3rem;
  margin-left:-16em;
  left: 16em;
  /*height: 10em;*/
  overflow: hidden;
  background: transparent;
  width:100%;
  height:5em;
  transition: all 0.25s cubic-bezier(0.4, 0.01, 0.165, 0.99);

  @media (max-width: 768px) {
    height:0px;
  }
`;

const SiteNavLeft = styled.div`
  justify-content: center;
  margin: auto;
  width: 100%;

  /* YESSSS */
  /*padding-right: 20em !important;*/

  flex: 1 0 auto;
  display: flex;
  align-items: center;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  margin-right: 10px;
  padding: 10px 0 80px;
  font-weight: 500;
  letter-spacing: 0.2px;
  text-transform: uppercase;
  white-space: nowrap;
  height: 23em;
  margin: 0;
  overflow:hidden;

  -ms-overflow-scrolling: touch;

  @media (max-width: 768px) {
    margin-right: 0;
    padding-left: 0vw;
  }
`;

const SiteNavContent = styled.div`
  position: relative;
  align-self: flex-start;
  width: 100vw;
  display: flex;
`;

const NavItems = styled.div`
  margin:auto;
  width:100% !important;

  /* YESSSS */
  /*padding-left:43% !important;*/

`;


const NavStyles = css`
  width: 100%;
  margin: auto;
  position: absolute;
  z-index: 1000;
  display: flex;
  padding: 0;
  list-style: none;
  transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
  justify-content:center;
  text-align: center;
  transform-style:preserve-3d;

  li {
    display: inline-block;
    margin: 0;
    padding: 0;
  }

  li a {
    position: relative;
    display: block;
    padding: 12px 12px;
    color: #fff;
    opacity: 0.8;
    transition: opacity 0.25s ease-out;
    font-weight:600;
  }

  li a:hover {
    text-decoration: none;
    opacity: 1;
  }

  li a:before, li a:after {
    content: "";
    position: absolute;
    width: 0px;
    bottom:8px;
    height: 1px;
    right:50%;
    margin: 1px 0 0;
    transition: all 0.25s cubic-bezier(0.4, 0.01, 0.165, 0.99);
    transition-duration: 0.2s;
    opacity: 1;
    background-color: white;
  }

  li a:before{
    left:50%;
    right:0%;
  }

  li a:hover:before {
    left: 50%;
    opacity: 1;
    width: 33%;
  }

  li a:hover:after {
    right: 50%;
    opacity: 1;
    width: 33%;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    margin: auto;
    position: absolute;
    z-index: 1000;
    display: flex;
    padding: 0;
    list-style: none;
    transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
    background: black;
    bottom:0em;
    left:0;
    position:fixed;
    justify-content:center;

    li a {
      font-size:2.5vw;
      width: 20vw;
      text-align: center
    }

    .site-nav-logo {
      content:url(../../../../logo.png);
      width:21px;
      bottom:1px;
      overflow-x:scroll;

      @media (max-width: 768px) {
        margin: auto;
      }
     
    }

    }
`;

const SiteNavRight = styled.div`
  flex: 0 1 auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px 0;
  height: 64px;

  @media (max-width: 768px) {
    display: none;
  }
`;

const SocialLinks = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
`;

const SubscribeButton = styled.a`
  display: block;
  padding: 4px 10px;
  margin: 0 0 0 10px;
  border: #fff 1px solid;
  color: #fff;
  line-height: 1em;
  border-radius: 10px;
  opacity: 0.8;

  :hover {
    text-decoration: none;
    opacity: 1;
    cursor: pointer;
  }
`;

const NavPostTitle = styled.span`
  position: relative !important;
  display:flex;
  margin-left: 35vw;
  visibility: hidden;
  position: absolute;
  top: 9px;
  color: #fff;
  font-size: 1.7rem;
  font-weight: 400;
  text-transform: none;
  opacity: 0;
  transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
  transform: translateY(-175%);

  .dash {
    left: -25px;
  }

  .dash:before {
    content: '– ';
    opacity: 0.5;
  }
`;

const HideNav = css`
  ul {
    visibility: hidden;
    opacity: 0;
    transform: translateY(-175%);
    @media (max-width: 768px) {
      transform: translateY(175%);
    }
  }
  .nav-post-title {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
  }
`;

export default SiteNav;
